import React from 'react';
import get from 'lodash.get';
import { ifProp } from 'styled-tools';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BaseContainer from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ModularBlocks from 'components/ModularBlocks';
import BasePageWrapper from 'components/PageWrapper';
import MuiControlBar from 'components/ControlBar';
import pageShape from 'shapes/pageShape';
import SEO from 'components/SEO';
import useServerCacheHeaders from 'hooks/useServerCacheHeaders';
import httpCacheConfig, { addCacheTags } from 'config/httpCache';

const PageWrapper = styled(BasePageWrapper)`
  &.about-us-page,
  &.glossary-page,
  &.luckbox-page,
  &.tastyworks-page {
    padding-top: 0;
  }

  &.luckbox-page,
  &.tastyworks-page {
    padding-bottom: 0;
  }

  &.glossary-page {
    ${(props) => props.theme.breakpoints.down('sm')} {
      padding-bottom: 0;
    }

    .MuiBox-root {
      &:first-child {
        ${(props) => props.theme.breakpoints.down('sm')} {
          margin-bottom: 0;
        }
      }
    }
  }

  ${ifProp(
    '$noTopPadding',
    css`
      padding-top: 0;
    `
  )}

  &.learn-page {
    padding-top: 0;

    .MuiBox-root {
      &:first-child {
        margin-top: 0;

        .modular-block-fullBleedCtaBanner {
          padding-top: 0;
        }
      }
    }
  }
`;

const Container = styled(BaseContainer)`
  margin-bottom: 3rem;

  &.legal-stuff-page,
  &.contact-us-page,
  &.faq-page {
    ${(props) => props.theme.breakpoints.up('lg')} {
      max-width: 1380px;
    }
  }

  &.trader-resources-page {
    margin-bottom: 0;

    ${(props) => props.theme.breakpoints.up('md')} {
      padding-left: 4rem;
      padding-right: 4rem;
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      max-width: 100%;
      padding-left: 8.75rem;
      padding-right: 8.75rem;
    }

    @media (min-width: 1820px) {
      padding-left: 11.5rem;
      padding-right: 11.5rem;
    }
  }
`;

const PageTitle = styled(Typography)`
  font-size: 1.5rem;
  margin-bottom: 2.25rem;
  margin-top: 0;
  padding-top: 2.375rem;
  text-transform: uppercase;

  ${ifProp(
    { $page: 'trader-resources' },
    css`
      margin-bottom: 0;
    `
  )}
`;

const ControlBar = styled(MuiControlBar)`
  ${ifProp(
    { $page: 'trader-resources' },
    css`
      border-bottom: none;
    `
  )}

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;

    ${ifProp(
      { $page: 'events' },
      css`
        display: flex;
      `
    )}

    ${ifProp(
      { $page: 'contact-us' },
      css`
        display: flex;
      `
    )}

    ${ifProp(
      { $page: 'our-platforms' },
      css`
        display: flex;
      `
    )}
  }
`;

const propTypes = {
  page: pageShape.isRequired,
  noTopPadding: PropTypes.bool,
};

function UtilityPage({ page, ...props }) {
  const pageName = get(page, 'url').replace('/', '');
  const { pageBody, seo, title, displayPageTitle, noTopPadding = false } = page;
  useServerCacheHeaders(
    addCacheTags(httpCacheConfig.utilityPage, [page.uid, 'header', 'footer'])
  );

  return (
    <>
      <SEO title={title} seo={seo} />
      <PageWrapper className={`${pageName}-page`} $noTopPadding={noTopPadding}>
        {displayPageTitle && (
          <Container className={`${pageName}-page`} $page={pageName}>
            {title && (
              <PageTitle variant='h1' $page={pageName}>
                {title}
              </PageTitle>
            )}
            <ControlBar color='medium' $page={pageName} />
          </Container>
        )}
        <ModularBlocks modularBlocks={pageBody} page={pageName} {...props} />
      </PageWrapper>
    </>
  );
}

UtilityPage.propTypes = propTypes;

export default React.memo(UtilityPage);
