import { useServerContext } from 'context/ServerContext/ServerContext';

function useServerCacheHeaders({ maxAge = 0, cacheTags = [], scope = 'public' }, { skip = false } = {}) {
  const serverContext = useServerContext();

  if (!serverContext || process.env.RAZZLE_DISABLE_HTTP_CACHING === 'true') {
    return;
  }

  if (skip) {
    serverContext.cacheControl = {};
    return;
  }

  serverContext.cacheControl = {
    ...(serverContext.cacheControl || {}),
    maxAge,
    cacheTags,
    scope,
  };
}

export default useServerCacheHeaders;
