import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import BaseGrid from '@material-ui/core/Grid';
import { ifProp, theme } from 'styled-tools';

const Grid = styled(BaseGrid)`
  border-bottom: 1px solid ${theme('colors.lightGray')};

  ${ifProp({ $color: 'medium' }, css`
    border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  `)}
`;

ControlBar.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
};

function ControlBar({ children, color, ...props }) {
  return (
    <Grid container justify='space-between' alignItems='flex-end' $color={color} {...props}>
      {children}
    </Grid>
  );
}

export default ControlBar;
